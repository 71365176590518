import { onINP } from 'web-vitals/attribution.js'

import { MetricType, InpSimpleEventEntry, InpRequest } from '../../functions/src/share/types'

// Tracker URL
const trackerUrl = 'https://trackevent-432pwbouzq-an.a.run.app'

export async function trackEvent(projectId: string, metricType: MetricType, message: InpRequest) {
  if (!window.fetch) return

  const url = new URL(trackerUrl)
  url.searchParams.set('projectId', projectId)
  url.searchParams.set('metric', metricType)

  // Post message to tracker
  const response = await fetch(url.href, {
    method: 'POST',
    body: JSON.stringify(message),
    keepalive: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw new Error(`Failed to publish message: ${response.statusText}`)
  }
}

export function trackInp(projectId: string, threshold: number) {
  onINP((signal) => {
    if (signal.value < threshold) {
      return
    }

    const processedEventEntries: InpSimpleEventEntry[] = signal.attribution.processedEventEntries.map((entry) => ({
      duration: entry.duration,
      entryType: entry.entryType,
      interactionId: entry.interactionId,
      name: entry.name,
      startTime: entry.startTime,
    }))

    const trackingData: InpRequest = {
      projectId,
      url: location.href,
      value: signal.value,
      delta: signal.delta,
      navigationType: signal.navigationType,
      rating: signal.rating,
      attribution: {
        ...signal.attribution,
        processedEventEntries,
      },
    }

    trackEvent(projectId, 'inp', trackingData)
  })
}
